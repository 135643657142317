<template src="./sendmoneyforcustomer.html"></template>
<style scoped src="./sendmoneyforcustomer.css">

</style>
<script>
import transactionConstants from '@/constants/TransactionConstants.vue'
import accountService from '@/services/AccountService.vue'
import transactionService from '@/services/TransactionService.vue'
import { required, minValue } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import Loading from 'vue-loading-overlay'
import xmlUtils from '@/utils/utils.vue'
import getRecipients from '@/components/modal/getRecipient/recipient.vue'
import modal from '@/components/modal/notification/modal'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import pagination from '@/components/vtable/vtpagination.vue'

export default {
    components: {
        Loading, getRecipients, modal, pagination
    },
    data() {
        return {
            disabled: false,
            transactionPurpose: transactionConstants.transactionPurpose,
            senderEmail: '',
            tPurpose: '',
            recipientData: null,
            pcmBalance: '',
            sourceCurrency: '',
            columns: ['DateTime', 'ReferenceNumber', 'TransactionType', 'Amount', 'Action'],
            transactionSummary: [],
            parentReferenceNumber: '',
            requestedAmount: '',
            promoCode: '',
            remittanceOptions: [],
            remittanceOption: {},
            recipientList: [],
            loaderVisible: false,
            approveRemit: false,
            customerIsReliable: false,
            exchangeRate: '',
            qicSendFee: '',
            sourceAmount: '',
            destAmount: '',
            parentTransactionId: '',
            errorMessages: applicationConstants.formsMessages,
            showGetRecipients: true,
            Utils: xmlUtils,
            AccountWhitleListed: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            modalLoaderVisible: false,
            isLazyLoadingEnabled: applicationConstants.enableLazyLoading
        }
    },
    created() {
        if (this.$route.params.type) {
            this.pageLoad(this.$route.params)
            this.showGetRecipients = false
        }
    },
    methods: {
        ...mapActions(['fetchRemittanceOption']),
        async pageLoad(recipientData) {
            try {
                this.senderEmail = recipientData.email
                this.disabled = recipientData.type === 'sendmoney'
                this.recipientData = recipientData
                this.loaderVisible = true

                await accountService.methods.GetAccountBalance(this.senderEmail)
                    .then(res => {
                        this.pcmBalance = res.csAccountBalanceResponse.Balance._text ? res.csAccountBalanceResponse.Balance._text : ''
                        this.sourceCurrency = res.csAccountBalanceResponse.Currency._text ? res.csAccountBalanceResponse.Currency._text : ''
                    })
                    .catch(err => {
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
                if (!this.getRemittanceOptions2)
                    await this.fetchRemittanceOption()
                this.remittanceOptions = this.getRemittanceOptions2
                if (this.recipientData.type === 'sendmoney') {
                    this.remittanceOption = this.remittanceOptions.find(el => el.RemittanceOptionId === this.recipientData.RemittanceGatewayId)
                    await accountService.methods.GetAccountDetails(this.senderEmail)
                    .then(res => {
                        this.AccountWhitleListed = (res.csGetUserProfileResponse.WhitelistedForLimits._text).toLowerCase() === 'true'
                    })
                    await transactionService.methods.GetTransactionSummary({ CustomerEmail: this.senderEmail })
                    .then(res => {
                        let transactionSummaryHistory = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                        if (transactionSummaryHistory) {
                            transactionSummaryHistory = Array.isArray(transactionSummaryHistory) ? transactionSummaryHistory : transactionSummaryHistory
                        } else {
                            transactionSummaryHistory = []
                        }
                        this.transactionSummary = transactionSummaryHistory.filter(el => (el.TransactionStatus._text === '1' && el.TransactionType._text === 'From Bank Account') || (el.TransactionType._text === 'E-Transfer' && el.TransactionStatus._text === '1'))
                    })
                    .catch(err => {
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
                }

                //from resendmoneytransfer module
                if (this.recipientData.type === 'resendmoneytransfer') {
                    await transactionService.methods.GetTransactionSummary({ ReferenceNumber: this.recipientData.ParentReferenceNumber })
                        .then(async res => {
                            let transSummary = this.removeUnderscoreText(res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem)
                            if (transSummary.length > 0) {
                                //Verify the fund transaction has enabled in application config table.
                                if (transSummary[0].SenderEmail && transSummary[0].TransactionId && transSummary[0].ParentReferenceNumber) {
                                    this.parentReferenceNumber = transSummary[0].ParentReferenceNumber
                                    this.recipientData.RecipientName = transSummary[0].RecipientName
                                    await this.getSendMoneyDetails(transSummary[0].SenderEmail, transSummary[0].TransactionId)
                                }
                            }
                        })
                        .catch(err => {
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                }
                this.loaderVisible = false
            } catch (error) {
                this.loaderVisible = false
            }
        },
        async getSendMoneyDetails(senderEmail, transactionId) {
            //Get Transaction Details
            await transactionService.methods.GetTransactionDeatils({ CustomerEmail: senderEmail, TransactionId: transactionId })
                .then(res => {
                    if (res.csTransactionDetailsResponse.TransactionData.RemittanceData?._text) {
                        let moneyTransferRequestSavedData = xmlUtils.methods.getParseObjectFromXml(res.csTransactionDetailsResponse.TransactionData.RemittanceData._text)
                        if (moneyTransferRequestSavedData) {
                            this.recipientData.RecipientId = moneyTransferRequestSavedData.MoneyTransferRequestSavedData.RecipientId?._text
                            this.tPurpose = moneyTransferRequestSavedData.MoneyTransferRequestSavedData.Purpose?._text
                            this.requestedAmount = moneyTransferRequestSavedData.MoneyTransferRequestSavedData.RequestedAmount?._text
                            this.requestedDestinationAmount = moneyTransferRequestSavedData.MoneyTransferRequestSavedData.RequestedDestinationAmount?._text
                        }
                        this.sourceAmount = res.csTransactionDetailsResponse.TransactionData.SourceAmount?._text
                    } else {
                        this.recipientData.RecipientId = res.csTransactionDetailsResponse.TransactionData.Recipient?.Id?._text
                        this.tPurpose = res.csTransactionDetailsResponse.TransactionData.TransactionPurposeId?._text
                        this.requestedAmount = res.csTransactionDetailsResponse.TransactionData.RequestedAmount?._text
                    }
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
            //GetRecipients 
            await transactionService.methods.GetRecipientsDetails(senderEmail, '', '')
                .then(recipientRes => {
                    this.recipientList = this.removeUnderscoreText(recipientRes.csGetRecipientsResponse.RecipientItems.RecipientItem)
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
            //find Recipient
            let recipientItem = this.recipientList.find(item => item.Id === this.recipientData.RecipientId)
            if (recipientItem) {
                this.remittanceOption = this.remittanceOptions.find(rem => rem.RemittanceOptionId === recipientItem.RemittanceGatewayId)
                this.recipientData.RemittanceGatewayId = this.remittanceOption.RemittanceOptionId
                this.recipientData.RemittanceCurrency = this.remittanceOption.Currency
            }
            await this.calculateTransactionFee() //call calculate Transaction fee 
        },
        async getRecipientsData(recipient) {
            this.pageLoad({
                email: recipient.email,
                RecipientId: recipient.Id,
                RecipientName: recipient.FirstName + ' ' + recipient.LastName,
                RemittanceGatewayId: recipient.RemittanceGatewayId,
                RemittanceCurrency: recipient.RemittanceCurrency,
                Country: recipient.Country,
                type: 'sendmoney'
            })
            this.clearFields()
            this.showGetRecipients = false
        },
        parentTransLookUp(e) {
            let modal = document.getElementById('parentTransactionModal')
            modal.style.display = 'block' 
            let closeButton = document.getElementsByClassName('close')[0]
            closeButton.onclick = function () {
                    modal.style.display = 'none'
            }
        },
        async sendMoney(event) {
            try {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    event.preventDefault()
                } else {
                    this.$bvModal.msgBoxConfirm('Are you sure you want to Send Money?')
                        .then(value => value ? (this.recipientData.type === 'sendmoney' ? this.checkSendMoneyAgainestParentTransaction() : this.sendMoneyEvent()) : null)
                        .catch(err => {
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                }
            } catch (error) {
                //
            }
        },
        async checkSendMoneyAgainestParentTransaction() {
            let validRecipient = false, validAmount = false, sessionId
            //check Send Money Against Parent Transaction
            await transactionService.methods.GetTransactionDeatils({
                CustomerEmail: this.senderEmail,
                TransactionId: this.parentTransactionId
            })
                .then(res => {
                    if (res.csTransactionDetailsResponse) {
                        if (res.csTransactionDetailsResponse.TransactionData) {
                            if (res.csTransactionDetailsResponse.TransactionData.TransactionType?._text === 'From Bank Account' || res.csTransactionDetailsResponse.TransactionData.TransactionType?._text === 'E-Transfer') {
                                if (res.csTransactionDetailsResponse.TransactionData.RemittanceData) {
                                    let MoneyTransferRequestSavedData = xmlUtils.methods.getParseObjectFromXml(res.csTransactionDetailsResponse.TransactionData.RemittanceData?._text)
                                    if (MoneyTransferRequestSavedData) {
                                        if (this.recipientData.RecipientId === MoneyTransferRequestSavedData.MoneyTransferRequestSavedData.RecipientId?._text) {
                                            validRecipient = true
                                            validAmount = (MoneyTransferRequestSavedData.MoneyTransferRequestSavedData.RequestedAmount?._text).toFixed(2) === (this.requestedAmount).toFixed(2)
                                        }
                                    }
                                }
                            }
                            sessionId = res.csTransactionDetailsResponse.TransactionData.SessionId?._text
                        }
                    }
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
            if (!validAmount || !validRecipient) {
                if (xmlUtils.methods.checkUserACL('OverrideParentTransaction')) {
                    if (!validRecipient) {
                        this.$bvModal.msgBoxConfirm('Recipient is not valid for this parent transaction.Do you wish to continue with recipient you selected?')
                            .then(result => result ? this.sendMoneyEvent(sessionId) : null)
                    } else {
                        this.$bvModal.msgBoxConfirm('Requested Amount entered is not matched with the send amount in parent transaction. Do you wish to continue with amount you entered?')
                            .then(result => result ? this.sendMoneyEvent(sessionId) : null)
                    }
                } else {
                    !validRecipient ? this.showAlert(null, 'Recipient is not valid for this parent transaction. You are not allowed for this override. Please contact high level support / Adminsitrator.')
                        : this.showAlert(null, 'Requested Amount entered is not matched with the send amount in parent transaction. You are not allowed for this override. Please contact high level support / Adminsitrator.')
                    this.loaderVisible = false
                }
            }
        },
        async sendMoneyEvent(sessionId = null) {
            try {
                this.loaderVisible = true
                if (this.promoCode !== '' && this.promoCode) {
                    // Redeem Promo code
                    let promoc = this.promoCode.split('-')
                    let IssueNumber = promoc[(promoc.length) - 1]
                    let Code = promoc[0]
                    if (promoc.length > 0 && this.recipientData.RemittanceGatewayId && this.requestedAmount) {
                        await transactionService.methods.RedeemPromoCode({
                            Amount: this.requestedAmount,
                            Code: Code,
                            IsssueNumber: IssueNumber,
                            ParentReferenceNumber: this.parentReferenceNumber,
                            RemittanceGateway: this.recipientData.RemittanceGatewayId,
                            ValidateOnly: 'false',
                            customerEmail: this.senderEmail
                        })
                            .then(res => { })
                            .catch(err => {
                                this.loaderVisible = false
                                this.alertObject = err
                                this.$bvModal.show(this.$refs.childModal.id)
                                setTimeout(() => { this.goBack() }, 5000)
                            })
                    }
                }
                //to check receiver bank account is disabled or not
                // if(this.isDisabledBank(this.senderEmail,this.recipientData.RecipientId)){
                //     
                // }

                // csSendMoneyApicall
                // get SessionId
                if (!sessionId) {
                    await transactionService.methods.GetTransactionSummary({ ParentReferenceNumber: this.parentReferenceNumber })
                        .then(async res => {
                            let transactionSummary = this.removeUnderscoreText(res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem)
                            if (transactionSummary) {
                                let transactionSummaryItem = transactionSummary.find(el => el.TransactionType === 'From Bank Account' || el.TransactionType === 'E-Transfer')
                                if (transactionSummaryItem) {
                                    await transactionService.methods.GetTransactionDeatils({ CustomerEmail: this.senderEmail, TransactionId: transactionSummaryItem.TransactionId })
                                        .then(response => {
                                            sessionId = response.csTransactionDetailsResponse.TransactionData.SessionId?._text
                                        })
                                        .catch(error => {
                                            this.alertObject = error
                                            this.$bvModal.show(this.$refs.childModal.id)
                                        })
                                }
                            }
                        })
                        .catch(err => {
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                }
                await transactionService.methods.SendMoney({
                    Fee: this.qicSendFee,
                    OriginCurrency: this.sourceCurrency,
                    ParentReferenceNumber: this.parentReferenceNumber,
                    Purpose: this.tPurpose,
                    RecipientId: this.recipientData.RecipientId,
                    XferAmount: this.requestedAmount,
                    ApproveRemit: this.approveRemit,
                    CustomerEmail: this.senderEmail,
                    CustomerIsReliable: this.customerIsReliable,
                    SessionId: sessionId,
                    RequestedDestinationAmount: this.destAmount
                })
                    .then(res => {
                        this.loaderVisible = false
                        this.$router.push({
                            name: 'Confirmation',
                            params: {
                                resSendMoney: res.csSendMoneyResponse.TransactionData,
                                parentReferenceNumber: res.csSendMoneyResponse.ParentReferenceNumber
                            }
                        })
                    })
                    .catch(err => {
                        this.loaderVisible = false
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                        setTimeout(() => { this.goBack() }, 3000)
                    })
            } catch (error) {
                this.loaderVisible = false
            }
        },
        isDisabledBank(customerEmail, recipientId) {
            try {
                let recipientInfo = this.recipientList.find(el => el.Id === recipientId)
                if (recipientInfo) {
                    if (recipientInfo.RemittanceGatewayId === '2') {
                        let customProperties = this.removeUnderscoreText(recipientInfo.CustomProperties)
                        let bankCode = customProperties.find(el => el.Name === 'bankCode')
                        if (bankCode) {
                            //check with disabled bank codes list if it contain return true
                        }
                    }
                }
                return false
            } catch (error) {
                //
            }
        },
        fillParentReferenenceNumber(row) {
            try {
                this.parentReferenceNumber = row.ParentReferenceNumber
                this.parentTransactionId = row.TransactionId
                document.getElementById('parentTransactionModal').style.display = 'none'
            } catch (error) {
                //
            }
        },
        async checkPromoCode() {
            try {
                const clearFields = () => {
                    this.exchangeRate = ''
                    this.qicSendFee = ''
                    this.sourceAmount = ''
                    this.destAmount = ''
                }
                if (this.recipientData.type === 'sendmoney') {
                    this.$v.requestedAmount.$touch()
                    if (this.$v.requestedAmount.$invalid) {
                        clearFields()
                        return
                    }
                    if (+this.requestedAmount < 50 && !this.AccountWhitleListed) {
                        this.requestedAmount = ''
                        clearFields()
                        this.showAlert(null, 'We require $50 CAD as the minimum transfer amount for security purposes. Please enter an amount of $50 or above.')
                        return
                    }
                }
                if (!this.promoCode.includes('-') && this.promoCode.length > 0) {
                    this.promoCode = ''
                    this.showAlert(null, 'Invalid Code Format')
                    return
                }
                this.loaderVisible = true
                this.calculateTransactionFee()
            } catch (error) {
                //
            }
        },
        async calculateTransactionFee() {
            let res = await xmlUtils.methods.calculateTransationFee({
                DestCountry: this.recipientData.Country,
                DestCurrency: this.remittanceOption.Currency,
                OriginCountry: 'Canada',
                OriginCurrency: 'CAD',
                RemittanceGatewayId: this.recipientData.RemittanceGatewayId,
                SendAmountOrigin: this.requestedAmount
            }, this.promoCode, this.senderEmail)
            this.loaderVisible = false
            if (!res.Error || res.ErrorCode === '2000') {
                if (res.ErrorCode === '2000') {
                    this.alertObject = res.Error
                    this.$bvModal.show(this.$refs.childModal.id)
                }
                this.exchangeRate = res.ExchangeRate
                this.qicSendFee = res.Fee
                this.sourceAmount = res.SourceAmount
                this.destAmount = res.DestAmount
            } else {
                this.promoCode = ''
                this.alertObject = res.Error
                this.$bvModal.show(this.$refs.childModal.id)
            }
        },
        removeUnderscoreText(data) {
            if (data) {
                data = Array.isArray(data) ? data : [data]
                data.forEach(obj => Object.keys(obj).map(key => obj[key] = obj[key]._text ? obj[key]._text : null))
            } else {
                data = []
            }
            return data
        },
        clearFields() {
            this.parentReferenceNumber = ''
            this.requestedAmount = ''
            this.tPurpose = ''
            this.promoCode = ''
            this.exchangeRate = ''
            this.qicSendFee = ''
            this.sourceAmount = ''
            this.destAmount = ''
            this.customerIsReliable = false
            this.approveRemit = false
            this.$v.$reset()
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        goBack() {
            if (this.recipientData.type === 'resendmoneytransfer') {
                this.$router.push({
                    name: 'ResendMoneyTransfer',
                    params: {
                        resendMoneyTransferParams: this.$route.params?.resendMoneyTransferParams
                    }
                })
            } else {
                this.showGetRecipients = true
            }
        }
    },
    validations: {
        parentReferenceNumber: { required },
        requestedAmount: { required, minValue: minValue(0.001) },
        tPurpose: { required },
    },
    computed: {
        ...mapGetters(['getRemittanceOptions2']),
        ParentTransactionSummary() {
            let transactionSummary = []
            if(this.transactionSummary && this.transactionSummary.length > 0) {
                transactionSummary = JSON.parse(JSON.stringify(this.transactionSummary))
                if(this.isLazyLoadingEnabled) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    transactionSummary = transactionSummary.slice((selectedPage - 1)* 10, selectedPage * 10)
                }
                transactionSummary = this.removeUnderscoreText(transactionSummary)
                transactionSummary.forEach(item => item.DateTime = xmlUtils.methods.UTCtoLocal(moment(moment(item.DateTime, 'MM/DD/YYYY hh:mm:ss')._d).format('MM-DD-YYYY HH:mm:ss')))
            }
            const updatePagiantion = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.transactionSummary?.length ?? 0 
                }
            }
            if(this.isLazyLoadingEnabled)
                updatePagiantion()
            return transactionSummary
        }
    }
}
</script>